/**
 * @file An example module extending the the functionality of Bootstraps »Foo«
 * module or an own module which has nothing to do with Bootstraps JavaScript
 * code.
 * @author Daniel J. Becker <winter-support@danieljbecker.de>
 */

/**
 * Make sure our global object is available.
 * @namespace herrWinterFriseure
 * @ignore
 */
var herrWinterFriseure = window.herrWinterFriseure || {};

/**
 * Namespace of the module.
 * Aliasing the jQuery Namespace via Self Invoking Anonymous Function.
 * @namespace modulName
 * @memberof herrWinterFriseure
 * @param 	{jQuery} $	passing the jQuery object to make $ available even when
 *                    	using jQuery.noConflict()
 */
herrWinterFriseure.openingHours = (function($) {
	'use strict';

	// Opening Hours in Format: 'Tu,We,Fr 10:00-19:00'
	var selector = '[itemprop=openingHours]',
			// regex = /(?:(?:,*([a-z]{2})) *)|(\d{2}:\d{2})/ig,
			// regex = /(?:(?:,*([a-z]{2})) *)|(?:(\d{2}:\d{2})-(\d{2}:\d{2}))/ig,
			regex = /(?:([a-z]{2})+)+|(\d{2}:\d{2})-(\d{2}:\d{2})+/ig,
			dayMap = {
				0: 'su',
				1: 'mo',
				2: 'tu',
				3: 'we',
				4: 'th',
				5: 'fr',
				6: 'sa'
			},
			weekdays = {
				'mo': [],
				'tu': [],
				'we': [],
				'th': [],
				'fr': [],
				'sa': [],
				'su': []
			},
			now = null,
			templateOpened = '<p class="text-success">Wir haben derzeit <strong>geöffnet.</strong></p>',
			templateClosed = '<p class="text-warning">Derzeit geschlossen!</p>';
	
	var _getMatches = function(string, regex, index) {
		if(!index) { index = 1; }
		var matches = [];
		var match;
		while((match = regex.exec(string))) {
			if(match[index] !== undefined) {
				matches.push(match[index]);
			}
		}
		return matches;
	};

	/**
	 * Just an example public method that you could call from the global scope.
	 * @memberof herrWinterFriseure.modulName
	 * @public
	 * @param {string} message Message to write into the console.
	 * @example
	 * herrWinterFriseure.modulName.yourPublicMethod('Hi Public.');
	 */
	var init = function(selector) {
		if(!selector) {
			selector = '[itemprop=openingHours]';
		}
		_parseOpeningHours();
	};
	var initWithData = function(data) {
		weekdays = data;
	};
	var isOpen = function() {
		var today = dayMap[now.getDay()],
				hours = now.getHours(),
				minutes = now.getMinutes();
		var hoursToday = weekdays[today];
		if(hoursToday.length > 0) {
			var openingRange;
			for(var i = 0; i < hoursToday.length; i++) {
				openingRange = hoursToday[i];
				if((openingRange.from.hours <= hours && 
						openingRange.from.minutes <= minutes)) {
					if(hours < openingRange.to.hours) {
						return true;
					}else if(hours === openingRange.to.hours && 
									 minutes <= openingRange.to.minutes) {
						return true;
					}else {
						continue;
					}
				}else {
					continue;
				}
			}
		}
	};
	var displayStatus = function(selector) {
		$(selector).append((isOpen() ? templateOpened : templateClosed));
	};

	/**
	 * Just an example private method that you only can call from within this
	 * module.
	 * @memberof herrWinterFriseure.modulName
	 * @private
	 * @param {string} message Message to write into the console.
	 */
	var _parseOpeningHours = function() {
		$(selector).each(function(i, el, a) {
			// jshint unused:false
			var datetimeString = $(el).attr('datetime');
			var wds = _getMatches(datetimeString, regex, 1),
					hrsFrom = _getMatches(datetimeString, regex, 2)[0].split(':'),
					hrsTo = _getMatches(datetimeString, regex, 3)[0].split(':');
			$(wds).each(function(i, day, a) {
				// jshint unused:false
				weekdays[day.toLowerCase()].push({
					'from': {'hours': hrsFrom[0], 'minutes': hrsFrom[1]},
					'to': {'hours': hrsTo[0], 'minutes': hrsTo[1]}
				});
			});
		});
	};

	var _initialize = function() {
		now = new Date();
	};
	/**
	 * Executed on DOM ready within the scope of this module.
	 * @event
	 */
	$(function() {
		_initialize();
	});

	// Return functions to make them accessible from outside.
	return {
		init: init,
		initWithData: initWithData,
		isOpen: isOpen,
		displayStatus: displayStatus
	};

}(jQuery));

/**
 * Executed on DOM ready within the global scope.
 * @event
 */
$(function() {
	'use strict';

	herrWinterFriseure.openingHours.init();
});
