/**
 * @file An example module extending the the functionality of Bootstraps »Foo«
 * module or an own module which has nothing to do with Bootstraps JavaScript
 * code.
 * @author Daniel J. Becker <winter-support@danieljbecker.de>
 */

/**
 * Make sure our global object is available.
 * @namespace herrWinterFriseure
 * @ignore
 */
var herrWinterFriseure = window.herrWinterFriseure || {};

/**
 * Namespace of the module.
 * Aliasing the jQuery Namespace via Self Invoking Anonymous Function.
 * @namespace modulName
 * @memberof herrWinterFriseure
 * @param 	{jQuery} $	passing the jQuery object to make $ available even when
 *                    	using jQuery.noConflict()
 */
herrWinterFriseure.smoothScroller = (function($) {
	'use strict';
	var duration = 1050,
			offset = 1,
			easing = 'easeInOut';
	easing = function (x, t, b, c, d) {
		if(t===0) { return b; }
		if(t===d) { return b+c; }
		if((t/=d/2) < 1) { return c/2 * Math.pow(2, 10 * (t - 1)) + b; }
		return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
	};

	/**
	 * Just an example public method that you could call from the global scope.
	 * @memberof herrWinterFriseure.modulName
	 * @public
	 * @param {string} navLinkSelector 	Selector defining nav links, e.g.: 
	 *                                 	'.page-scroll a'
	 * @example
	 * herrWinterFriseure.modulName.yourPublicMethod('Hi Public.');
	 */
	var setup = function(navLinkSelector, os) {
		if(os) {
			offset = Number(os);
		}
		_setupWithSelector(navLinkSelector);
	};

	/**
	 * Just an example private method that you only can call from within this
	 * module.
	 * @memberof herrWinterFriseure.modulName
	 * @private
	 * @param {string} message Message to write into the console.
	 */
	var _setupWithSelector = function(navLinkSelector) {
		$('body').on('click', navLinkSelector, function(event) {
			var $anchor = $(this);
			$('html, body').stop().animate({
				scrollTop: $($anchor.attr('href')).offset().top -offset +1 // adding 1px as a tolerance threshold for scrollspy
			}, duration, easing);
			event.preventDefault();
		});
	};
	var _init = function() {
		offset = Number($('[data-spy]').attr('data-offset'));
	};

	/**
	 * Executed on DOM ready within the scope of this module.
	 * @event
	 */
	$(function() {
		_init('Init Smooth Scroller.');
	});

	// Return functions to make them accessible from outside.
	return {
		setup: setup
	};

}(jQuery));

/**
 * Executed on DOM ready within the global scope.
 * @event
 */
$(function() {
	'use strict';

	// herrWinterFriseure.smoothScroller.setup('Hi public.');
});
